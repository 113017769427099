import styled from 'styled-components'
import theme from 'styles/theme'
import { Eyebrow as ThemeEyebrow, Title60 } from 'components/Typography'

export const Wrapper = styled.div`
  text-align: left;
`

export const Carousel = styled.div`
  text-align: left;
`

export const Heading = styled.div`
  text-align: center;
  width: 100%;
`

export const Eyebrow = styled(ThemeEyebrow)`
  color: ${theme.colors.sagedark};
  margin-bottom: 10px;
`

export const Headline = styled(Title60)`
  margin: 0;
`
export const Container = styled.div`
  text-align: center;
  width 100%;
`
