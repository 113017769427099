import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VizSensor from 'react-visibility-sensor'
import Button from 'components/Button'
import Link from 'components/Link'
import { Spacing50 } from 'styles/sharedStyle'
import {
  Wrapper,
  Carousel,
  Eyebrow,
  Heading,
  Headline,
  Container,
} from './StacklaWidget.style'

class stacklaWidget extends Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    const d = document
    const id = 'stackla-widget-js'
    let t = d.scripts[d.scripts.length - 1].previousElementSibling
    const el = d.scripts[d.scripts.length - 1].previousElementSibling

    if (el) el.dataset.initTimestamp = new Date().getTime()
    if (d.getElementById(id)) return
    t = d.createElement('script')
    t.src = '//assetscdn.stackla.com/media/js/widget/fluid-embed.js'
    t.id = id

    const h =
      d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]
    h.appendChild(t)
  }

  render() {
    const { code, eyebrow, headline, ctaLabel, ctaUrl } = this.props

    return (
      <Wrapper>
        <Spacing50 />
        <Heading>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Headline>{headline}</Headline>
        </Heading>
        <Spacing50 />
        <VizSensor
          partialVisibility
          offset={{
            top: 500,
          }}
          onChange={() => {
            const { loaded } = this.state

            if (window.Stackla && !loaded) {
              window.Stackla.WidgetManager.rebuild()
              this.setState({ loaded: true })
            }
          }}>
          <Carousel
            dangerouslySetInnerHTML={{
              __html: code,
            }}
          />
        </VizSensor>
        {!!ctaLabel && !!ctaUrl && (
          <Container>
            <Spacing50 />
            <Button
              className="stacklaWidgetCta"
              as={Link}
              level="quaternary"
              to={ctaUrl}
              external={ctaUrl.indexOf('http') === 0}
              fixedSize="true">
              {ctaLabel}
            </Button>
          </Container>
        )}
      </Wrapper>
    )
  }
}

stacklaWidget.propTypes = {
  eyebrow: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
}

export default stacklaWidget
