import React from 'react'
import PropTypes from 'prop-types'
import { Spacing50 } from 'styles/sharedStyle'
import MarkdownRenderer from 'components/MarkdownRenderer'
import SocialSharing from '../SocialSharing'
import {
  ArticleIntroByline,
  ArticleIntroCopy,
  ArticleIntroInner,
  ArticleIntroMeta,
  ArticleIntroPublished,
  ArticleIntroWrapper,
} from './Article.style'

const ArticleIntro = ({
  authorName,
  byLabel,
  copy,
  hidePublished,
  published,
  share,
  shareUrl,
}) => {
  const citation = [byLabel, authorName].filter((s) => !!s).join(' ')
  return (
    <ArticleIntroWrapper>
      <Spacing50 />
      <ArticleIntroInner>
        <MarkdownRenderer
          as={ArticleIntroCopy}
          html={copy?.childMarkdownRemark?.html}
        />
        {(!!authorName || !hidePublished) && (
          <ArticleIntroMeta>
            {!!authorName && (
              <ArticleIntroByline>{citation}</ArticleIntroByline>
            )}
            {!hidePublished && (
              <ArticleIntroPublished>{published}</ArticleIntroPublished>
            )}
          </ArticleIntroMeta>
        )}
        {share && <SocialSharing urlToShare={shareUrl} />}
      </ArticleIntroInner>
      <Spacing50 />
    </ArticleIntroWrapper>
  )
}

ArticleIntro.propTypes = {
  authorName: PropTypes.string,
  byLabel: PropTypes.string,
  copy: PropTypes.string.isRequired,
  hidePublished: PropTypes.bool,
  published: PropTypes.string,
  share: PropTypes.bool,
  shareUrl: PropTypes.string,
}

ArticleIntro.defaultProps = {
  authorName: '',
  byLabel: '',
  hidePublished: false,
  published: '',
  share: false,
  shareUrl: '',
}

export default ArticleIntro
