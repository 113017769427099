import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import ArticleIntro from 'components/Article/ArticleIntro'
import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import {
  filterRepeatedContent,
  decorateListicleContent,
  groupContentTypes,
} from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'

const nonRepeatedContentTypes = [
  'ContentfulCompCarouselFullBleedCar03',
  'ContentfulCompCarouselArticleCar01',
]

const renderArticleContent = (articleContent, pageProps) => {
  const filteredContent = decorateListicleContent(
    groupContentTypes(
      filterRepeatedContent(articleContent, nonRepeatedContentTypes)
    )
  )
  return renderContent(filteredContent, pageProps)
}

const EditorialPage = (props) => {
  const {
    data,
    location: { href: pageUrl },
    pageContext: { pageLevel },
  } = props
  const {
    page,
    page: {
      jsonLdSchema,
      articleContent,
      authorName,
      byLabel,
      hero,
      hidePublished,
      published,
      share,
      standFirst,
      stacklaCarousel,
      village,
    },
  } = data

  const articeIntroProps = {
    authorName,
    byLabel,
    copy: standFirst,
    hidePublished,
    published,
    share,
    shareUrl: pageUrl,
  }

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        image: heroImage,
        eyebrow: hero.eyebrow,
        content: hero.headline,
        logo: page.brandLogo,
        video: heroVideo,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      <ArticleIntro {...articeIntroProps} />
      {articleContent &&
        articleContent.length &&
        renderArticleContent(articleContent, props)}

      <Spacing50 />

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCarousel.ctaUrl}
        />
      )}
    </Layout>
  )
}

EditorialPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const editorialPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateEditorialT08(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      locale: node_locale
      authorName
      byLabel
      standFirst {
        childMarkdownRemark {
          html
        }
      }
      published(formatString: "D MMMM YYYY")
      hidePublished
      share
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      hero {
        headline
        eyebrow
        isCondensed
        opacity
        ...heroMediaQuery
      }
      articleContent {
        __typename
        ... on Node {
          ...multipleComponentsT08
        }
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default EditorialPage
