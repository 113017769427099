/* eslint-disable no-underscore-dangle */
export const createFilterDictionary = (typesToFilter) =>
  typesToFilter.reduce((dictionary, type) => {
    return { ...dictionary, [type]: false }
  }, {})

export const filterRepeatedContent = (content, typesToFilter) => {
  const filterDictionary = createFilterDictionary(typesToFilter)

  return content.reduce((filteredContent, props) => {
    if (typesToFilter.includes(props.__typename)) {
      if (!filterDictionary[props.__typename]) {
        filteredContent.push(props)
        filterDictionary[props.__typename] = true
      }
    } else {
      filteredContent.push(props)
    }
    return filteredContent
  }, [])
}

export const decorateListicleContent = (content) => {
  let indexListicle = 0
  return content.reduce((decoratedContent, props) => {
    if (props.__typename === 'ContentfulCompListicleLis01') {
      indexListicle += 1
      decoratedContent.push({
        ...props,
        number:
          indexListicle < 10
            ? `0${indexListicle}`.slice(-2)
            : `${indexListicle}`,
      })
    } else {
      decoratedContent.push(props)
    }
    return decoratedContent
  }, [])
}

export const groupContentTypes = (
  content,
  types = ['ContentfulCompPromoCopyCtaPro01']
) => {
  const groupedContent = []
  let group = false

  Array.prototype.forEach.call(content, (component, i) => {
    const { __typename: contentType } = component
    const shouldGroup = types.indexOf(contentType) > -1
    const lastOfType =
      !content[i + 1] || content[i + 1].__typename !== contentType

    if (shouldGroup) {
      group = group || {
        __typename: `${contentType}Collection`,
        collection: [],
      }

      group.collection.push(component)

      if (lastOfType) {
        groupedContent.push(group)
        group = false
      }
    } else {
      groupedContent.push(component)
    }
  })

  return groupedContent
}

export const NON_REPEATED_CONTENT_TYPES_T02 = [
  'ContentfulCompCarouselFullBleedCar03',
  // 'ContentfulCompSectionHeaderSch01',
  'ContentfulCompStepsStp01',
  'ContentfulCompTabsTab01',
  'ContentfulCompBulletListBul01',
]

export const serviceType = {
  ShoppingExpress: 1,
}
